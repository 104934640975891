import React from 'react';
import {
  useTheme,
  Container,
  Box,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import Domain from '../assets/Domain.png';
import NetlifyPage1 from '../assets/NetlifyPage1.png';
import NetlifyPage2 from '../assets/NetlifyPage2.png';
import NetlifyPage3 from '../assets/NetlifyPage3.png';
import NetlifyPage4 from '../assets/NetlifyPage4.png';
import FroylanEncinas from '../assets/FroylanEncinas.jpeg';
import IsaganiCanete from '../assets/IsaganiCanete.jpeg';
import NetlifySecondSiteTakedownNotification from '../assets/NetlifySecondSiteTakedownNotification.png';
import AccountRequest from '../assets/AccountRequest.png';
import EmailFiling from '../assets/EmailFiling.png';

const Important = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="IMPORTANT"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - IMPORTANT"
      />

      <Paper
        elevation={5}
        sx={{
          bgcolor: colors.primary[400],
          alignItems: 'center',
          borderRadius: '6px',
          padding: isMobile ? '10px' : '20px',
          display: 'flex',
          flexDirection: isMobile ? 'col' : 'row',
          marginBottom: '-15px',
          overflow: 'hidden',
          wordWrap: 'break-word',
        }}
      >
        <img
          src={IsaganiCanete}
          alt="Isagani Canete"
          width={isMobile ? '80px' : '120px'}
          height={isMobile ? '80px' : '120px'}
          style={{ borderRadius: '10px' }}
        />
        <Box
          sx={{
            marginLeft: isMobile ? '10px' : '40px',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <Typography
            variant={isMobile ? 'h3' : 'h1'}
            fontWeight="bold"
            sx={{
              wordBreak: 'break-word',
              color: colors.redAccent[500],
              textAlign: 'center',
            }}
          >
            CRIMINAL ACT BY ASCENSION ST.VINCENT
          </Typography>
          <br />

          <Typography
            variant={isMobile ? 'h4' : 'h2'}
            fontWeight="bold"
            sx={{ wordBreak: 'break-word' }}
          >
            After everything else failed, the SPINELESS COWARDS at Ascension St.
            Vincent hired an outside party (Isagani Canete, Cebu, Central
            Visayas, Philippines) to acquire my web hosting credentials, posing
            as me in yet another effort to bring my website down.
            <br />
            <br />
            This is the height of DECEPTION by a SO CALLED FAITH BASED HOSPITAL.
          </Typography>
          <br />
          {/* <br /> */}
          <Typography
            variant={isMobile ? 'h4' : 'h2'}
            fontWeight="bold"
            sx={{ wordBreak: 'break-word', color: colors.redAccent[500] }}
          >
            The person responsible for impersonating me on behalf of Ascension
            St. Vincent to bring my website AscensionStVincent.com down.
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h4' : 'h3'}>
            <Box component="span" sx={{ color: colors.redAccent[500] }}>
              🔗{' '}
              <a
                href="https://www.linkedin.com/in/isagani-canete-076928171/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: colors.blueAccent[300],
                }}
              >
                Isagani Canete on LinkedIn
              </a>
            </Box>
          </Typography>
        </Box>
      </Paper>
      <br />
      <br />
      <Paper
        elevation={5}
        sx={{
          bgcolor: colors.primary[400],
          alignItems: 'center',
          borderRadius: '6px',
          padding: isMobile ? '10px' : '20px',
          display: 'flex',
          flexDirection: isMobile ? 'col' : 'row',
          marginBottom: '-15px',
          overflow: 'hidden',
          wordWrap: 'break-word',
        }}
      >
        <img
          src={AccountRequest}
          alt="Account Request"
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '6px',
          }}
        />
      </Paper>
      <br />
      <br />
      <Paper
        elevation={5}
        sx={{
          bgcolor: colors.primary[400],
          alignItems: 'center',
          borderRadius: '6px',
          padding: isMobile ? '10px' : '20px',
          display: 'flex',
          flexDirection: isMobile ? 'col' : 'row',
          marginBottom: '-15px',
          overflow: 'hidden',
          wordWrap: 'break-word',
        }}
      >
        <img
          src={EmailFiling}
          alt="EmailFiling"
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '6px',
          }}
        />
      </Paper>
      <br />
      <br />
      <Paper
        elevation={5}
        sx={{
          bgcolor: colors.primary[400],
          alignItems: 'center',
          borderRadius: '6px',
          padding: isMobile ? '10px' : '20px',
          display: 'flex',
          flexDirection: isMobile ? 'col' : 'row',
          marginBottom: '-15px',
          overflow: 'hidden',
          wordWrap: 'break-word',
        }}
      >
        <img
          src={FroylanEncinas}
          alt="Froylan Encinas"
          width={isMobile ? '80px' : '120px'}
          height={isMobile ? '80px' : '120px'}
          style={{ borderRadius: '10px' }}
        />
        <Box
          sx={{
            marginLeft: isMobile ? '10px' : '40px',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <Typography
            variant={isMobile ? 'h4' : 'h2'}
            fontWeight="bold"
            sx={{ wordBreak: 'break-word' }}
          >
            FROYLAN ENCINAS - TECHNICAL ENGINEERING SENIOR ANALYST AT AIS
          </Typography>
          <br />
          {/* <br /> */}
          <Typography
            variant={isMobile ? 'h4' : 'h2'}
            fontWeight="bold"
            sx={{ wordBreak: 'break-word', color: colors.redAccent[500] }}
          >
            The person responsible for bringing my website
            AscensionStVincent.com down.
          </Typography>
          {/* </Typography> */}
        </Box>
      </Paper>
      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* NEW */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            margin: '20px -20px 20px -20px',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            variant={isMobile ? 'h4' : 'h2'}
            color="error"
            gutterBottom
          >
            <strong>
              COWARDS at Ascension St. Vincent took down my website TWICE to
              silence my efforts to expose their negligence and
              unprofessionalism!
            </strong>
          </Typography>

          <Typography variant={isMobile ? 'h4' : 'h2'} paragraph>
            I urge you to review the{' '}
            <strong>emails I received from domain.com and netlify.com</strong>{' '}
            detailing this action.
          </Typography>

          <Typography variant={isMobile ? 'h4' : 'h2'} paragraph>
            Instead of accepting responsibility for their mistakes,{' '}
            <strong>Ascension St. Vincent's management</strong> chose to
            retaliate. They assigned one of their employees, <br />
            <strong style={{ color: 'red' }}>FROYLAN ENCINAS</strong>, to
            execute this act.
          </Typography>
          {/* NEW */}
          <Typography variant={isMobile ? 'h4' : 'h3'}>
            <Box component="span" sx={{ color: colors.redAccent[500] }}>
              🔗{' '}
              <a
                href="https://www.linkedin.com/in/froylan-encinas-083641a1/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: colors.blueAccent[300],
                }}
              >
                Froylan Encinas on LinkedIn
              </a>
            </Box>
          </Typography>
          {/* NEW */}
          <Typography variant={isMobile ? 'h4' : 'h2'} paragraph>
            <strong style={{ color: 'red' }}>
              This is an attack on transparency and accountability.
            </strong>{' '}
            <br />
            Please share, comment, and support the fight for justice.
          </Typography>
        </Paper>
        {/* NEW */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <img
            src={Domain}
            alt="Domain Email"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '6px',
            }}
          />
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <img
            src={NetlifyPage1}
            alt="Netlify Email"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '6px',
            }}
          />
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <img
            src={NetlifyPage2}
            alt="Netlify Email"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '6px',
            }}
          />
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <img
            src={NetlifyPage3}
            alt="Netlify Email"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '6px',
            }}
          />
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <img
            src={NetlifyPage4}
            alt="Netlify Email"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '6px',
            }}
          />
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <img
            src={NetlifySecondSiteTakedownNotification}
            alt="Netlify Email"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '6px',
            }}
          />
        </Paper>
      </Box>
    </Container>
  );
};

export default Important;
